// https://gist.github.com/chibicode/fe195d792270910226c928b69a468206#file-twemoji-js

import React, { memo } from 'react'
import twemoji from '@twemoji/api'
import "./Twemoji.css";

const Twemoji = ({ emoji }) => (
    <span
        className={"emoji"}
        dangerouslySetInnerHTML={{
            __html: twemoji.parse(emoji, {
                folder: 'svg',
                ext: '.svg'
            })
        }}
    />
)

export default memo(Twemoji)
